<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix modal-heading">
      <span>Update {{ existing.name }}</span>
      <el-button type="text" @click="$modal.hide('company-edit-form')">
        <font-awesome-icon icon="window-close" fixed-width></font-awesome-icon>
      </el-button>
    </div>

    <el-form
      @submit.native.prevent="update"
      :label-position="$root.windowWidth > 767 ? 'right' : 'top'"
      label-width="100px"
    >
      <el-form-item
        :error="form.errors.first('name') ? form.errors.first('name') : null"
        label="Name"
        required
      >
        <el-input
          v-model="company.name"
          type="text"
          placeholder="Name"
          @focus="form.errors.clear('name')"
          v-input-focus
        ></el-input>
      </el-form-item>

      <el-form-item
        :error="form.errors.first('users') ? form.errors.first('users') : null"
        label="Users"
      >
        <modal-select>
          <el-select
            v-model="company.users"
            :style="{ width: '100%' }"
            multiple
            clearable
            placeholder="Assign Users..."
            @focus="form.errors.clear('users')"
          >
            <el-option
              v-for="item in clients"
              :key="item.slug"
              :label="item.name"
              :value="item.slug"
            ></el-option>
          </el-select>
        </modal-select>
      </el-form-item>

      <el-form-item
        :error="form.errors.first('reps') ? form.errors.first('reps') : null"
        label="Reps"
      >
        <modal-select>
          <el-select
            v-model="company.reps"
            :style="{ width: '100%' }"
            multiple
            clearable
            placeholder="Assign Representatives..."
            @focus="form.errors.clear('reps')"
          >
            <el-option
              v-for="item in reps"
              :key="item.slug"
              :label="item.name"
              :value="item.slug"
            >
            </el-option>
          </el-select>
        </modal-select>
      </el-form-item>

      <el-form-item
        :error="form.errors.first('color') ? form.errors.first('color') : null"
        label="Brand Color"
      >
        <el-col :md="24" class="brand-color-container">
          <el-color-picker
            class="brand-color-picker"
            v-model="company.color"
          ></el-color-picker>
          <ul class="detected-colors-list">
            <a
              class="has-cursor-pointer"
              v-for="(color, index) in detectedColors['primary']"
              :key="'p' + index"
            >
              <li
                :style="{
                  'background-color': color,
                  color: invertColor(color),
                }"
                @click="setBrandColor(color)"
              >
                {{ color }}
              </li>
            </a>
            <a
              class="has-cursor-pointer"
              v-for="(color, index) in detectedColors['secondary']"
              :key="'s' + index"
            >
              <li
                :style="{
                  'background-color': color,
                  color: invertColor(color),
                }"
                @click="setBrandColor(color)"
              >
                {{ color }}
              </li>
            </a>
          </ul>
        </el-col>
      </el-form-item>

      <el-form-item
        :error="form.errors.first('logo') ? form.errors.first('logo') : null"
        label="Brand Logos"
      >
        <el-col
          :sm="12"
          style="
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            min-height: 160px;
          "
        >
          <el-upload
            class="logo-uploader"
            drag
            action="#"
            :http-request="uploadPrimaryLogo"
            :show-file-list="false"
            v-loading="loading.primaryLogo"
          >
            <img
              v-if="company.primary_logo_large"
              :src="company.primary_logo_large"
              class="logo"
            />
            <p class="uploader-container-text" v-else>
              <font-awesome-icon
                icon="cloud-upload-alt"
                style="margin-bottom: 0.5rem"
                size="3x"
              ></font-awesome-icon>
              <br />
              Drag or click to upload Primary Logo.
            </p>
          </el-upload>
          <el-button
            type="primary"
            size="small"
            plain
            v-if="company.primary_logo_large"
            @click="removeLogo('primary')"
            >Remove Logo</el-button
          >
        </el-col>
        <el-col
          :sm="12"
          style="
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            min-height: 160px;
          "
        >
          <el-upload
            class="logo-uploader"
            drag
            action="#"
            :http-request="uploadSecondaryLogo"
            :show-file-list="false"
            v-loading="loading.secondaryLogo"
          >
            <img
              v-if="company.secondary_logo_large"
              :src="company.secondary_logo_large"
              class="logo"
            />
            <p class="uploader-container-text" v-else>
              <font-awesome-icon
                icon="cloud-upload-alt"
                size="3x"
                style="margin-bottom: 0.5rem"
              ></font-awesome-icon>
              <br />
              Drag or click to upload Secondary Logo.
            </p>
          </el-upload>
          <el-button
            type="primary"
            size="small"
            plain
            v-if="company.secondary_logo_large"
            @click="removeLogo('secondary')"
            >Remove Logo</el-button
          >
        </el-col>
      </el-form-item>

      <el-button
        type="primary"
        plain
        medium
        native-type="submit"
        v-loading="loading.form"
        :style="{ width: '100%' }"
      >
        Update
      </el-button>
    </el-form>
  </el-card>
</template>

<style lang="scss">
.logo-uploader .el-upload-dragger {
  width: 160px;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  width: 150px;
  height: 150px;
}

.brand-color-container {
  display: flex;
  align-items: center;

  .brand-color-picker {
    display: inline-flex;
  }

  .detected-colors-list {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 0.8rem;
    text-align: center;
    display: inline-flex;

    li {
      line-height: 1rem;
      display: inline-block;
      padding: 5px;
      border-radius: 4px;
    }

    a {
      margin-left: 5px;
    }

    a:first-child {
      margin-left: 0.5rem;
    }
  }
}
</style>

<script>
import Form from "form-backend-validation"
import huey from "huey"
import { mapActions, mapGetters } from "vuex"

export default {
  name: "CompanyEditForm",

  mixins: [],

  props: {
    existing: {
      required: true,
    },
  },
  data() {
    return {
      company: {},
      form: new Form(),
      loading: {
        form: false,
        primaryLogo: false,
        secondaryLogo: false,
      },
      detectedColors: {
        primary: [],
        secondary: [],
      },
    }
  },
  computed: {
    ...mapGetters({
      users: "users/list",
    }),
    clients() {
      return this.$_.filter(this.users, function (o) {
        return o.role === "approval-client" || o.role === "client"
      })
    },
    reps() {
      return this.$_.filter(this.users, function (o) {
        return o.role === "super" || o.role === "admin" || o.role === "employee"
      })
    },
  },

  methods: {
    ...mapActions({
      refreshUsers: "users/refresh",
      updateCompany: "companies/update",
      updateAuth: "auth/update",
    }),
    uploadPrimaryLogo(data) {
      this.uploadAvatar(data, "primary")
    },
    uploadSecondaryLogo(data) {
      this.uploadAvatar(data, "secondary")
    },
    uploadAvatar(data, type) {
      let formData = new FormData()
      formData.append("file", data.file)
      formData.append("type", type)

      this.loading[type + "Logo"] = true

      this.axios
        .post("companies/logo/" + this.company.slug, formData)
        .then((response) => {
          this.updateCompany(response.data.data)
          this.updateAuth()
          this.refresh(response.data.data)
          this.loading[type + "Logo"] = false
          this.$emit("update:file", data)
          this.$message.success(response.data.message)
        })
        .catch((resp) => {
          this.loading[type + "Logo"] = false
          this.$emit("update:file", {})
          if (resp.response && resp.response.status === 422) {
            this.form.onFail(resp)
          } else {
            this.$root.globalRequestError(resp)
          }
        })
    },
    removeLogo(type) {
      let formData = new FormData()
      formData.append("file", null)
      formData.append("type", type)

      this.loading[type + "Logo"] = true

      this.axios
        .post("companies/logo/" + this.company.slug, formData)
        .then((response) => {
          this.updateCompany(response.data.data)
          this.updateAuth()
          this.refresh(response.data.data)
          this.loading[type + "Logo"] = false
          this.$emit("update:file", {})
          this.$message.success(response.data.message)
          this.detectedColors[type] = []
        })
        .catch((resp) => {
          this.loading[type + "Logo"] = false
          if (resp.response && resp.response.status === 422) {
            this.form.onFail(resp)
          } else {
            this.$root.globalRequestError(resp)
          }
        })
    },
    refresh(data) {
      this.company = this.$_.cloneDeep(data)
      console.log('company: ', this.company)
      this.company.users = this.$_.map(this.company.users, "slug")
      this.company.reps = this.$_.map(this.company.reps, "slug")
      if (this.company.primary_logo_large) {
        this.setDetectedColorsFromExisting(
          this.company.primary_logo_large,
          "primary"
        )
      }
      if (this.company.secondary_logo_large) {
        this.setDetectedColorsFromExisting(
          this.company.secondary_logo_large,
          "secondary"
        )
      }
    },
    update() {
      this.loading.form = true

      let formData = {
        name: this.company.name,
        users: this.company.users,
        reps: this.company.reps,
        color: this.company.color,
      }

      this.axios
        .post("companies/update/" + this.company.slug, formData)
        .then((response) => {
          this.updateCompany(response.data.data)
          this.$modal.hide("company-edit-form")
          this.$message.success(response.data.message)
          this.loading.form = false
        })
        .catch((resp) => {
          this.loading.form = false
          if (resp.response && resp.response.status === 422) {
            this.form.onFail(resp)
          } else {
            this.$root.globalRequestError(resp)
          }
        })
    },
    setDetectedColorsFromExisting(url, type) {
      let blob = null
      let xhr = new XMLHttpRequest()

      xhr.open("GET", url)
      xhr.responseType = "blob"
      xhr.onload = () => {
        blob = xhr.response
        this.readLogoColors(blob, type)
      }
      xhr.send()
    },
    readLogoColors(file, type) {
      let reader = new FileReader()
      let that = this
      reader.onload = function (e) {
        let url = e.target.result
        that.detectedColors[type] = []
        huey.palette(url, 3, function (error, palette) {
          palette.forEach(function (rgb) {
            that.detectedColors[type].push(
              that.rgbToHex(rgb[0], rgb[1], rgb[2])
            )
          })
        })
      }
      reader.readAsDataURL(file)
    },
    rgbToHex(r, g, b) {
      return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)
    },
    invertColor(hex) {
      if (hex.indexOf("#") === 0) {
        hex = hex.slice(1)
      }
      if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
      }
      if (hex.length !== 6) {
        throw new Error("Invalid HEX color.")
      }
      var r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16)

      return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#303133" : "#FAFAFA"
    },
    setBrandColor(color) {
      this.company.color = color
    },
  },

  mounted() {
    this.refreshUsers({page:1})
    this.refresh(this.existing)
  },
}
</script>
